import { DocumentNode, gql } from 'apollo-boost';

export interface QueryBaseProps {
  locale: string | string[];
}

export const seoFragment = `
  head {
    title,
    description,
    ogType,
    ogImage {
      data {
        attributes {
          url
        }
      }
    }
  }
`;

export const sharedcopy = `
  sharedCopy {
    data {
      attributes {
        global
      }
    }
  }
`;

export const ctaSectionFragment = `
... on ComponentPageCtaSection {
  title
  copy
  name
  position
  ctaLabel
  picture {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  links {
    label
    href
  }
}
`;

export const processQuery = locale => `
process (locale: "${locale}") {
  data {
    attributes {
      process {
        title
        steps {
          stepTitle
          stepDescription
          fullTitle
          fullDescription
          images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
}`;

export const contactQuery = locale => `
contactPage (locale: "${locale}"){
  data {
     attributes {
      title
      cta {
        label
        href
      }
      leaveContactPlaceholder
      leaveContactCopy
      letsMeetCopy
      legalCopy
      contactInformation {
        title
        info
      }
    }
  }
}`;

export const articleQuery = `
data {
  attributes {
    ${seoFragment}
    worthReading {
        data {
          attributes {
            title
            shortDescription
            slug
          }
        }
      }
    categories {
      data {
        attributes {
          name
        }
      }
    }
    updatedAt
    title
    author
    shortDescription
    slug
    hero {
      data {
        attributes {
          url
          alternativeText
        }
      }
    }
    timeToRead
    sections {
      ${ctaSectionFragment}
      ... on ComponentArticleArticle {
        title
        content
        additionalMarginTop
        additionalMarginBottom
      }
      ... on ComponentArticleMediaSection {
        title
        additionalMarginTop
        additionalMarginBottom
        media {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
      ... on ComponentArticleImageSection {
        description
        alt
        variant
        desktopWidthPercentage
        additionalMarginTop
        additionalMarginBottom
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const landingPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${headData(locale)}
    ${sharedCopies(locale)}
    landingPage (locale: "${locale}") {
      data {
        attributes {
          ${seoFragment}
          sections {
            ${ctaSectionFragment}
            ...  on ComponentLandingPageLandingSection {
              text
              firstStateCta {
                label
                href
              }
              secondStateCta {
                label
                href
              }
            }
            ... on ComponentLandingPageServicesSection {
              title
              description
              services {
                name
                description
                ${service}
              }
            }

            ... on ComponentLandingPageProcessSection {
              title
              description
            }

            ... on ComponentLandingPageArticles {
              title
              description
              featured {
                ${articleQuery}
              }
            }

            ... on ComponentLandingPageContactSection {
              title
              description
            }
          }
        }
      }
    }
    ${processQuery(locale)}
    ${contactQuery(locale)}
    ${footer(locale)}
    ${cookies(locale)}
  }
`;

export const aboutPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${headData(locale)}
    ${sharedCopies(locale)}
    ${footer(locale)}
    ${cookies(locale)}
    # faqPage (locale: "en"){
    #   data {
    #     attributes {
    #       title
    #       faqs {
    #         question
    #         answer
    #       }
    #     }
    #   }
    # }
    servicesPage (locale: "${locale}") {
      data {
        attributes {
          ${seoFragment}
          ${cta}
          faqTitle
          faqs {
            question
            answer
          }
          sections {
            ${ctaSectionFragment}
            ... on ComponentServicesPageLandingSection {
              title
              description
            }
            ... on ComponentServicesPageServiceList {
              services {
                data {
                  attributes {
                    slug
                    name
                    values {
                      slug
                      title
                      text
                      relatedArticles {
                        data {
                          attributes {
                            title
                            shortDescription
                          }
                        }
                      }
                    }
                    icon {
                      data {
                        attributes {
                          url
                          alternativeText
                        }
                      }
                    }
                  }
                }
              }
              title
            }
            ... on ComponentServicesPageWhyUs {
              title
              whyUs {
                data {
                  attributes {
                    title
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const blogPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${sharedCopies(locale)}
    ${sharedCopies(locale)}
    ${headData(locale)}
    ${footer(locale)}
    ${cookies(locale)}

    blogPage (locale: "${locale}") {
      data {
        attributes {
          ${seoFragment}
          title
          ${cta}
        }
      }
    }

    articles (locale: "${locale}") {
      ${articleQuery}
    }
  }
`;

export const processPageQuery = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${sharedCopies(locale)}
    ${sharedCopies(locale)}
    ${headData(locale)}
    ${footer(locale)}
    ${cookies(locale)}
    ${processQuery(locale)}
    processPage(locale: "${locale}") {
      data {
        attributes {
          ${seoFragment}
          cta {
            label
            href
          }
          ctaSection {
            title
            copy
            name
            position
            ctaLabel
            picture {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            links {
              label
              href
            }
          }
        }
      }
    }
  }
`;

export const contactPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${sharedCopies(locale)}
    ${sharedCopies(locale)}
    ${headData(locale)}
    ${footer(locale)}
    ${cookies(locale)}

    contactPage (locale: "${locale}"){
      data {
        attributes {
          ${seoFragment}
          title
          cta {
            label
            href
          }
          leaveContactPlaceholder
          leaveContactCopy
          letsMeetCopy
          legalCopy
          steps {
            name
            description
          }
          contactInformation {
            title
            info
          }
        }
      }
    }
  }
`;

export const faqPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    faqPage (locale: "${locale}"){
      data {
        attributes {
          title
          faqs {
            question
            answer
          }
        }
      }
    }
  }
`;

export const pricacyPage = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    ${sharedCopies(locale)}
    ${headData(locale)}
    ${sharedCopies(locale)}
    ${footer(locale)}
    ${cookies(locale)}

    privacy (locale: "${locale}") {
      data {
        attributes {
          ${seoFragment}
          title
          content
        }
      }
    }
  }
`;

export const articlePage = ({
  locale = process.env.DEFAULT_LOCALE,
  slug,
}): DocumentNode => gql`
  {
    ${sharedCopies(locale)}
    ${headData(locale)}
    ${sharedCopies(locale)}
    ${footer(locale)}
    ${cookies(locale)}

    articles (locale: "${locale}", filters: {slug: {eq: "${slug}"}}) {

      ${articleQuery}
    }
  }
`;

export const notFoundPageQuery = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    sharedCopy(locale: "${locale}"){
      data {
        attributes {
          global
        }
      }
    }
  }
`;

export const getAllArticles = (): DocumentNode => gql`
  {
    articles {
      data {
        attributes {
          slug
          locale
        }
      }
    }
  }
`;

export const sharedCopies = (locale: QueryBaseProps['locale']) => `
sharedCopy(locale: "${locale}") {
  data {
    attributes {
      global
    }
  }
}
`;

export const footer = (locale: QueryBaseProps['locale']) => `
footer (locale: "${locale}") {
  data {
    attributes {
      companyInfo
      contactInfo
      links
      legalLinks
    }
  }
}
`;

export const cookies = (locale: QueryBaseProps['locale']) => `
cookieBar (locale: "${locale}") {
  data {
    attributes {
      title
      description
      settingsCta
      acceptAllCta
      declineAllCta
      acceptCta
      rejectCta
      openSettingsCta
      saveCta
      cookies {
        title
        idx
        description
      }
    }
  }
}
`;

export const media = `
  data {
    attributes {
      url
      alternativeText
    }
  }
`;

export const cta = `
  cta {
    label
    href
  }
`;

export const headData = locale => `
  header (locale: "${locale}") {
    data {
      attributes {
        menuItems {
          label
          href
        }
        mobileCta {
          label
          href
        }
      }
    }
  }
`;

export const service = `
  service {
    data {
      attributes {
        name
        slug
        values {
          title
          text
        }
        icon {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
  }
`;
