import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  ${setTypography('display')}

  b {
    color: ${colors.green};
  }

  p {
    display: inline;
    opacity: 1;
  }
`;
