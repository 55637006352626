import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Bg = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
  z-index: 100;
  background-color: ${colors.backgroundGreen};
`;
