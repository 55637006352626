import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import axios from 'axios';
import fetch from 'isomorphic-unfetch';

import { normalize } from 'utils/cms/cmsUtility';

const graphQlUrl =
  process.browser && process.env.ENV === 'local'
    ? process.env.CMS_GRAPHQL_URL_LOCAL
    : process.env.CMS_GRAPHQL_URL;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const uri = process.env.CMS_GRAPHQL_URL;

const httpLink = createHttpLink({
  uri,
  fetch,
});

export const cmsApiClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions,
  credentials: 'include',
});

export const getLocales = async () =>
  await fetch(uri.replace('/graphql', '/i18n/locales')).then(data =>
    data.json()
  );

export const queryCMS = async params => {
  const response = await cmsApiClient.query(params);

  return normalize(JSON.parse(JSON.stringify(response)));
};

export const axiosInstance = axios.create({
  baseURL: graphQlUrl?.replace('/graphql', ''),
  timeout: 30000, // 30s
});
