import { HTMLMotionProps } from 'framer-motion';
// import { animate, stagger } from 'framer-motion/dom';
import React, { useEffect } from 'react';

import * as S from './Title.styles';

export interface TitleProps {
  className?: string;
  children?: any;
}

const defaultProps: Partial<TitleProps> = {};

const Title = React.forwardRef<
  HTMLDivElement,
  TitleProps & HTMLMotionProps<'div'>
>(({ className, children, ...rest }, ref) => {
  useEffect(() => {
    // animate('p', { opacity: 1 }, { delay: stagger(0.1) });
  }, []);

  return (
    <S.Wrapper ref={ref} className={className} {...rest}>
      {children}
    </S.Wrapper>
  );
});

Title.displayName = 'Title';
Title.defaultProps = defaultProps;

export default Title;
