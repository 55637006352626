import React from 'react';

import * as S from './TransitionBackground.styles';

export interface TransitionBackgroundProps {
  className?: string;
}

const defaultProps: Partial<TransitionBackgroundProps> = {};

export const transitionVariants = {
  initial: {
    // clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    opacity: 0,
    // scaleX: 1,
    // originX: 'left',
    transition: { duration: 0.4 },
  },
  animate: {
    // clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    opacity: 1,
    // scaleX: 0,
    // originX: 'left',
    transition: { duration: 0.4, staggerChildren: 0.1 },
  },
  exit: {
    // clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
    opacity: 0,
    // scaleX: 1,
    // originX: 'right',
    transition: { duration: 0.4 },
  },
};

const TransitionBackground: React.FC<TransitionBackgroundProps> = ({
  className,
}: TransitionBackgroundProps) => {
  return (
    <S.Bg
      initial="initial"
      animate="animate"
      exit="exit"
      variants={transitionVariants}
      className={className}
    ></S.Bg>
  );
};

TransitionBackground.defaultProps = defaultProps;

export default TransitionBackground;
